export const formatSources = [
  [
    "the-hill",
    "The Hill",
  ], 
  [
    "fox-news",
    "Fox",
  ], 
 [
    "the-new-york-times",
    "NYT",
  ], 
 [
    "cnn",
    "CNN",
  ], 
 [
    "abc-news",
    "ABC",
  ], 
 [
    "al-jazeera-english",
    "AJ",
  ], 
 [
    "bbc-news",
    "BBC",
  ], 
 [
    "bloomberg",
    "BLMBRG",
  ], 
 [
    "breitbart-news",
    "Breitbart",
  ], 
 [
    "business-insider",
    "B.Insder",
  ], 
  [
    "cbc-news",
    "CBC",
  ], 
  [
    "cbs-news",
    "CBS",
  ], 
  [
    "cnbc",
    "CNBC",
  ], 
  [
    "daily-mail",
    "Daily Mail",
  ], 
  [
    "financial-post",
    "F.Post",
  ], 
  [
    "msnbc",
    "MSNBC",
  ], 
  [
    "nbc-news",
    "NBC",
  ], 
  [
    "new-york-magainze",
    "NY MAG",
  ], 
  [
    "politico",
    "Politico",
  ], 
  [
    "reuters",
    "Reuters",
  ], 
  [
    "rt",
    "RT",
  ], 
  [
    "the-globe-and-mail",
    "Globe & Mail",
  ], 
  [
    "the-huffington-post",
    "Huff. Post",
  ], 
  [
    "the-irish-times",
    "Irish Times",
  ], 
  [
    "the-telegraph",
    "Telegraph",
  ], 
  [
    "the-wall-street-journal",
    "Wall Street Journal",
  ], 
  [
    "the-washington-post",
    "Washington Post",
  ], 
  [
    "the-washington-times",
    "Washington Times",
  ], 
  [
    "time",
    "Time",
  ], 
  [
    "usa-today",
    "USAToday",
  ], 
  [
    "vice-news",
    "Vice",
  ], 
];