import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Icon from "@material-ui/core/Icon";
import { useHistory } from "react-router-dom";

const NavBar = props => {
  let history = useHistory();

  var headStyle = {
    textAlign: "center",
    color: "black"
  };
  var barStyle = {
    backgroundColor: "white"
  };
  var biasStyle = {
    backgroundColor: "#F1C40F",
    padding: 5,
    color: "white"
  };

  var homeButton = clickedMenu => {
    if (clickedMenu === props.currentMenu) {
      history.push("/");
      props.clickEvent(1);
    } else if (clickedMenu === 0) {
      history.push("/menu");
      props.clickEvent(0);
    } else if (clickedMenu === 1) {
      history.push("/");
      props.clickEvent(1);
    } else if (clickedMenu === 2) {
      history.push("/about");
      props.clickEvent(2);
    }
  };

  return (
    <div>
      <AppBar style={barStyle} position="fixed">
        <h2 style={headStyle}>
          <span onClick={() => homeButton(0)}>
            <Icon style={{ float: "left", paddingLeft: 20, cursor: "pointer" }}>
              menu
            </Icon>
          </span>
          <span style={{ cursor: "pointer" }} onClick={() => homeButton(1)}>
            Read The <span style={biasStyle}>Bias</span>
          </span>
          <span onClick={() => homeButton(2)}>
            <span
              style={{
                float: "right",
                paddingRight: 20,
                fontSize: "0.7em",
                fontWeight: "300",
                cursor: "pointer"
              }}
            >
              FAQs
            </span>
          </span>
        </h2>
      </AppBar>
    </div>
  );
};

export default NavBar;
