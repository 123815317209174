const itemReducer = (state={
   cards:{
    card1: {
      active:1
    }, 
    card2: {
      active:1
    },
    card3: {
      active:1
    },
    card4: {
      active:1
    },
    card5: {
      active:1
    },

    card6: {
      active:1
    }, 
    card7: {
      active:1
    },
    card8: {
      active:1
    },
    card9: {
      active:1
    },
    card10: {
      active:1
    },


    card11: {
      active:1
    }, 
    card12: {
      active:1
    },
    card13: {
      active:1
    },
    card14: {
      active:1
    },
    card15: {
      active:1
    },


    card16: {
      active:1
    }, 
    card17: {
      active:1
    },
    card18: {
      active:1
    },
    card19: {
      active:1
    },
    card20: {
      active:1
    },

    card21: {
      active:1
    }, 
    card22: {
      active:1
    }, 
  }
    
  },action) => {
    if(action.type === 'SHOW_ITEM'){
      var cardNumber = action.payload;
      state = {
        cards:{
          card1: {
            active:1
          }, 
          card2: {
            active:1
          },
          card3: {
            active:1
          },
          card4: {
            active:1
          },
          card5: {
            active:1
          },

          card6: {
            active:1
          }, 
          card7: {
            active:1
          },
          card8: {
            active:1
          },
          card9: {
            active:1
          },
          card10: {
            active:1
          },


          card11: {
            active:1
          }, 
          card12: {
            active:1
          },
          card13: {
            active:1
          },
          card14: {
            active:1
          },
          card15: {
            active:1
          },


          card16: {
            active:1
          }, 
          card17: {
            active:1
          },
          card18: {
            active:1
          },
          card19: {
            active:1
          },
          card20: {
            active:1
          },

          card21: {
            active:1
          }, 
          card22: {
            active:1
          }, 
          [cardNumber]: {
            active:action.src
          }
        }
      }
    
    }
    else {
     }
    
    return state;
  
  };

  export default itemReducer;
  