import React, {Component} from "react";
import Grid from "@material-ui/core/Grid"; 
import { Link} from "react-router-dom";



export default class Menu extends Component {
    render(){

        var menuItem = {
            textAlign:"center",
            color:"black",
            textDecorationLine: 'none'
            
           
        }


        return(

            <div>
            <Grid
     container
     spacing={0}
     direction="column"
     alignItems="center"
     justify="center"
     style={{ minHeight: '100%',paddingTop:100,textAlign:"center"}}
   >
   
     <Grid item xs={12}>
        
     <div style={{padding:10, lineHeight:3}}>
     <h3>Sections</h3>
    <nav>
        <div>
        <Link style={menuItem} to="/">Home</Link>
        </div>
        <div>
        <Link style={menuItem} to="/about">About</Link>
        </div>
        <div>
        <span style={menuItem}>More Features Coming!</span> 
        </div>
        <div>
        <a style={menuItem} href="https://twitter.com/zedkay22">Reach out on Twitter:@zedkay22</a> 
        </div>
    </nav>
    </div>
     
    
     </Grid>   
   
   </Grid> 
         </div>
        )
    }

}