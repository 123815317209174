import React from "react";
import "../../App.css";
import tutorialLogo from "../img/tutorial.png";

class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.closePopup();
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup\_inner">
          <div
            ref={this.wrapperRef}
            onClick={e => console.log(e)}
            className="tutorial-wrap"
          >
            <h2>How do I use this?</h2>

            <p>
              Each card displays a trending news topic and lists multiple news
              publications reporting on that topic.
            </p>
            <img alt="tutorial" className="tutorial-gif" src={tutorialLogo} />
            <p>
              1. Click on a news source to view the headline.
              <br />
              2. Click on a headline to read the full article.
              <br />
              3. Determine your own perspective after seeing how publications
              are trying to influence yours.
            </p>
            <button className="close-tutorial" onClick={this.props.closePopup}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
