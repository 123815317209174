import React  from 'react';
 
import { connect } from 'react-redux';
import { showItem } from "../actions/itemActions";
import { setupData } from "../actions/setupDataActions";
import { catChange } from "../actions/categoryActions";
import * as dbCall from "../actions/dbApi";
import Grid from '@material-ui/core/Grid';
import Cookies from 'universal-cookie';

 
import SingleCard from "./card.component";

const cookies = new Cookies();


 class GridaCAN extends React.Component{

 

  componentDidMount(){
 
 
    
     dbCall.dataFetch({
    method:"get",
    tk:cookies.get('token'),
    url:"https://rtb-backend1.herokuapp.com/rtb_mern/cat/canada",
    payload:"none"})
    .then((res)=>{  
     
      var pageData = {
        ...res,
 
      }
 
  
for(var key in pageData){

   

      var keynum = parseInt(key)+1;

      pageData[key]["card"] = "card"+keynum;

      
  
} 
 
this.props.setupData(pageData);
 
   }).catch(error => {
     console.log(error);
   }); 

   
  }

    render(){
 
   
//run the axios get command...
//use the pageContent as a model

//create pageDetailsobject.. 
     const catFunction = (newCat) => {
           this.props.catChange(newCat);

     }      

      const action = (arg,card) => {
            this.props.showItem(card,arg);
      };

      const contentObj = (active,card,category) => {

         if(active==="topic"){
           
          console.log(category);

          return this.props.setup[card];
 
        }else {

            var activeSource= "source"+active;

             var current_card = this.props.setup[card];
             return(current_card[activeSource]);
        }

      };
       
        var tagStyling = {
               padding:10,
               marginTop:100,
               textAlign:"center"
                            }

      return (
        <div>
         <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '50vh' }}
>
   <Grid item xs={12}>
     
  <h3 style={tagStyling}>Reporting the Headlines from All Sides</h3>

  <div className="countryNav">

  <a href={'/usa'}><img alt="usa-flag" onClick={()=> catFunction('usa')} src={require("../img/usa-flag-xs.png")} /></a>

  <a href={'/canada'}><img alt="canada-flag" onClick={()=> catFunction('canada')}src={require("../img/canada-flag-xs.png")} /></a>
  
  </div>
  <SingleCard topic={contentObj("topic","0",this.props.category.country)} cardNum = "1" action={(arg) => action(arg,"card1")} source={contentObj(this.props.item.cards.card1.active,"0")}/>
  <SingleCard topic={contentObj("topic","1",this.props.category.country)} cardNum = "2" action={(arg) => action(arg,"card2")} source={contentObj(this.props.item.cards.card2.active,"1")}/>
  <SingleCard topic={contentObj("topic","2",this.props.category.country)} cardNum = "3" action={(arg) => action(arg,"card3")} source={contentObj(this.props.item.cards.card3.active,"2")}/>
  <SingleCard topic={contentObj("topic","3",this.props.category.country)} cardNum = "4" action={(arg) => action(arg,"card4")} source={contentObj(this.props.item.cards.card4.active,"3")}/>
  {/* <SingleCard topic={contentObj("topic","4",this.props.category.country)} cardNum = "5" action={(arg) => action(arg,"card5")} source={contentObj(this.props.item.cards.card5.active,"4")}/> */}



  
  </Grid>   

</Grid> 
      </div>
      

      );
    } 
  }

    const mapStateToProps = (state) => {
      return {
        item: state.item,
        setup: state.setup,
        category: state.category.chosenCategory

      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        showItem: (cardNumber,activeSource) => {
          dispatch(showItem(cardNumber,activeSource));
        },
        setupData: (setupObj) => {
          dispatch(setupData(setupObj));
        },
        catChange: (catUpdate) => {
          dispatch(catChange(catUpdate));
        }
      };
    };
    
    export default connect(mapStateToProps,mapDispatchToProps)(GridaCAN);
    
