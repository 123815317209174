const createStoryReducer= (state={
     create: {
        story:"Enter the story",
        image:"Enter Image URL",
        date:"Enter current date",
        category:"Enter Category",
        source1:{
            number:"1",
            src:"Src 1",
            headline:"Headline1",
            link:"Link1",
            author:"Author",
            date:"Date",
            blurb:"blurb"
        },
        source2:{
          number:"2",
            src:"Src 2",
            headline:"Headline1",
            link:"Link1",
            author:"Author",
            date:"Date",
            blurb:"blurb"
        },
        source3:{
          number:"3",
            src:"Src 3",
            headline:"Headline1",
            link:"Link1",
            author:"Author",
            date:"Date",
            blurb:"blurb"
        },
        source4:{
          number:"4",
            src:"Src 4",
            headline:"Headline1",
            link:"Link1",
            author:"Author",
            date:"Date",
            blurb:"blurb"
        }
     }
   },action) => {
     if(action.type === 'UPDATE_CREATE'){
       
      if(action.src ===0){

        state = {
          ...state,
                  create:{
               ...state.create,
              [action.field]:action.value
            

                           }

                }

      }
      else {
        var sourceo = "source"+action.src;
        state = {
            ...state,
            create:{
              ...state.create,
                [sourceo] : {
                  ...state.create[sourceo],
                    [action.field]: action.value
                             }
                  }

                  }
         }
     }
     else {
      }
     
     return state;
   
   };
 
   export default createStoryReducer;
   