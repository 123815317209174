const fetchDataReducer = (state={

payload: {
    0: {headline:"headline",
    author:"author",
    link:"link",
    blurb:"blurb",
    src:"source",
    category:"category",
    date:"date"},
    1: {headline:"headline",
    author:"author",
    link:"link",
    blurb:"blurb",
    src:"source",
    category:"category",
    date:"date"}
}


},action) => {
     if(action.type === 'API_DATA'){
       var payload = action.payload;
       state = {
        payload
       }
     
     }
     else {
      }
     
     return state;
   
   };
 
   export default fetchDataReducer;
   