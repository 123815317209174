const categoryReducer = (state={

    chosenCategory: {
        country:'usa'
    }
    
    
    },action) => {
         if(action.type === 'CAT_CHANGE'){
           var payload = action.payload;
           var chosenCategory = state.chosenCategory;
           state = {
             ...state,
            chosenCategory:{
              ...chosenCategory,
              country: payload
            }
           }
         
         }    
         
         return state;
       
       };
     
       export default categoryReducer;
       