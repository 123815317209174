import React, {Component} from "react";

import { connect } from 'react-redux';
import { updateCreateStory } from "../../actions/createStoryActions";

import Grid from "@material-ui/core/Grid"; 
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
 import ContentFromTab from "../../components/admin/contentfromtab.component";
 import Button from '@material-ui/core/Button';
  import TextField from '@material-ui/core/TextField';

  import ImageEditorRc from 'react-cropper-image-editor';
  import 'cropperjs/dist/cropper.css'; 

import axios from 'axios';

// const SERVERURI = "https://rtb-backend1.herokuapp.com";


class createNew extends Component {


  state = {
    "view":false,
    }  

    componentDidMount(){
      function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }
  
    var tk = getCookie('token');
    console.log("tk is this: "+tk);
    var searchUrl = 'https://rtb-backend1.herokuapp.com/rtb_mern/adminTokenCheck';
    fetch(searchUrl,{
        method: 'POST', 
         headers: {
          'Content-Type': 'application/json',
          'x-access-token':tk
        }
      })
    .then(response => {
    if (response.status == 400 || response.status == 401 || response.status == 500 ){
      window.location.href = "/login";
     }
    else {
      this.setState({"view":true})
      return response.json();
  
    }
  
  
  });
   
    }


    render(){

    var topicStyling = {
      paddingRight:25,

    };

     var handleUpdate = (fieldName,fieldValue,src) => {

         this.props.updateCreateStory(fieldName,fieldValue,src);
      };


      var tophandleUpdate = (event) => {

        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        handleUpdate(fieldName, fieldValue,0);
        
      };


      const doSomething = (e) => {
                 e.preventDefault();



                 function getCookie(name) {
                  function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
                  var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
                  return match ? match[1] : null;
              }
            
              var tk = getCookie('token');
           


                 var currentdate = new Date().toISOString().slice(0,10);

         
 
        

        var newStory = { 
          ...this.props.content.create,
          date:currentdate
        }

 
        axios.post("https://rtb-backend1.herokuapp.com/rtb_mern/add",newStory,{
          'Content-Type': 'application/json',
          'x-access-token':tk,
        })
        .then(res => console.log(res.data));

 


      }

      var saveImage = (e) =>{
        console.log('prev');
         handleUpdate('image',e,0);
        console.log('handled');

      }
        return(

          
            <div>
  {this.state.view ? <div>  
            <Grid
     container
     spacing={0}
     direction="column"
      justify="center"
     style={{ minHeight: '100%',paddingTop:100,textAlign:"center"}}
   >
   
     <Grid item xs={12}>
        
     <div style={{padding:10, lineHeight:3}}>
     <h3>Create New Story</h3>
   
     <TextField
          style={topicStyling}
          id="story"
          name="story"
        onChange={(e) => tophandleUpdate(e)}
          label="Topic Title"
            margin="normal"
            value={this.props.content.create.story}
         className="textField"
        />
        <TextField
        style={topicStyling}
          id="image"
          name="image"
        onChange={(e) => tophandleUpdate(e)}
          label="Topic Image"
            margin="normal"
            value={this.props.content.create.image}
            className="textField"

        />

<TextField
        style={topicStyling}
          id="category"
          name="category"
        onChange={(e) => tophandleUpdate(e)}
          label="Topic Category"
            margin="normal"
            className="textField"

        />


<ImageEditorRc
        ref='cropper'
        crossOrigin='true' // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
        style={{width: '100%',textAlign:'center'}}
        aspectRatio={16 / 9}
        className={'imageEditor'}
        guides={true}
				rotatable={true} 
        imageName='imagename.jpg'
        saveImage={(e) => saveImage(e)} // it has to catch the returned data and do it whatever you want
        responseType='blob/base64'
        src={this.props.content.create.image}
        />


     <Tabs>
  <TabLink to="tab1">Source1</TabLink>
  <TabLink to="tab2">Source 2</TabLink>
  <TabLink to="tab3">Source 3</TabLink>
  <TabLink to="tab4">Source 4</TabLink>

 <form onSubmit={doSomething}>
   <TabContent fa="tab1" for="tab1">
   <ContentFromTab content={this.props.content.create.source1} handleaction={(e,v) => handleUpdate(e,v,1)} for="1"/>
   </TabContent>
   <TabContent fa="tab2" for="tab2">
   <ContentFromTab content={this.props.content.create.source2} handleaction={(e,v) => handleUpdate(e,v,2)} for="2"/>
   </TabContent>
   <TabContent fa="tab3" for="tab3">
   <ContentFromTab content={this.props.content.create.source3} handleaction={(e,v) => handleUpdate(e,v,3)} for="3"/>
   </TabContent>
   <TabContent fa="tab4" for="tab4">
   <ContentFromTab content={this.props.content.create.source4} handleaction={(e,v) => handleUpdate(e,v,4)} for="4"/>
   </TabContent>

   <Button onClick={doSomething}  type="submit" variant="contained" >CREATE</Button>
</form>


</Tabs>


    </div>
     
    
     </Grid>   
   
   </Grid>  </div>: null}
         </div>
        )
    }

}



const mapStateToProps = (state) => {
  return {
    content: state.createStory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCreateStory: (fieldName,fieldValue,src) => {
      dispatch(updateCreateStory(fieldName,fieldValue,src));
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(createNew);
