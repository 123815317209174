const toggleReducer = (state={

    toggle: 'off',
    
    
    },action) => {
         if(action.type === 'TOGGLE'){
           var payload = action.payload;
      
           state = {
             ...state,
           toggle:payload,
           }
         
         }    
         
         return state;
       
       };
     
       export default toggleReducer;
       