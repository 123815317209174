export function agenData(data){
    return{
        type:'GEN_DATA',
        payload:data
    };
} 

export function confirmTopic(data){
    return{
        type:'CONFIRM_TOPIC',
        payload:data
    };
} 

export function confirmImg(data){
    return{
        type:'CONFIRM_IMG',
        payload:data
    };
} 

export function confirmCat(data){
    return{
        type:'CONFIRM_CAT',
        payload:data
    };
} 

export function confirmSources(data,source){
    return{
        type:'CONFIRM_SOURCES',
        payload:data,
        theSource:source,
    };
} 

export function showConfirm(data){
    return{
        type:'SHOW_CONFIRM',
        payload:data
    };
} 