// import { createStore, combineReducers, applyMiddleware } from "redux";
// import logger from "redux-logger";

import { createStore, combineReducers } from "redux";

import item from "./reducers/itemReducer";
import toggle from "./reducers/toggleReducer";

import setup from "./reducers/setupDataReducer";
import createStory from "./reducers/createStoryReducer";
import fetchData from "./reducers/fetchDataReducer";
import agenData from "./reducers/genDataReducer";
import category from "./reducers/categoryReducer";

export default createStore(
  combineReducers({
    item,
    fetchData,
    createStory,
    agenData,
    setup,
    category,
    toggle
  }),
  {}
  // applyMiddleware(logger)
);
