import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Menu from "../components/menu.component";
import About from "../components/about.component";

import IsItDone from "../components/isitdone.component";

import Privacy from "../components/privacy.component";

import NavBar from "../components/navbar";
import Grida from "./grid.component";

import login from "./login";

import GridaCAN from "./grid.component.canada";
import createNew from "./admin/createnew.component";
import updateCard from "./admin/updatecard.component";
import suggestionTab from "./admin/suggestionTab.component";

class App extends React.Component {
  state = {
    //left = 0
    //main = 1
    //right = 2

    menuOpen: 1
  };

  render() {
    return (
      <Router>
        <div>
          <NavBar
            clickEvent={e => this.setState({ menuOpen: e })}
            currentMenu={this.state.menuOpen}
          />

          <Route path="/" exact component={Grida} />

          <Route path="/usa" exact component={Grida} />
          {/* <Route path="/canada" exact   component={GridaCAN} /> */}

          <Route path="/is-it-over" component={IsItDone} />

          <Route path="/about" component={About} />

          <Route path="/menu" component={Menu} />

          <Route path="/privacypolicy" component={Privacy} />

          <Route path="/login" component={login} />

          <Route path="/create/new" component={createNew} />
          <Route path="/update/new" component={updateCard} />
          <Route path="/suggest" component={suggestionTab} />
        </div>
      </Router>
    );
  }
}

export default App;
