import React from 'react';
 
import { connect } from 'react-redux';
 import { setupData } from "../../actions/setupDataActions";
 import Grid from '@material-ui/core/Grid';
 import * as dbCall from "../../actions/dbApi";
  
 const Child = props => (
     <tr>
         <td>{props.info.story}</td>
         <td>{props.info.date}</td>
         <td>Edit</td>
         <td>Delete</td>
     </tr>
 )
  class updateCard extends React.Component{

    state = {
      "view":false,
      }  
  
  

  componentDidMount(){
 
      function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1'); };
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }
  
    var tk = getCookie('token');
    console.log("tk is this: "+tk);
    var searchUrl = 'https://rtb-backend1.herokuapp.com/rtb_mern/adminTokenCheck';
    fetch(searchUrl,{
        method: 'POST', 
         headers: {
          'Content-Type': 'application/json',
          'x-access-token':tk
        }
      })
    .then(response => {
    if (response.status == 400 || response.status == 401 || response.status == 500 ){
      window.location.href = "/login";
     }
    else {
      this.setState({"view":true})

      
    dbCall.dataFetch({
      method:"get",
      url:"https://rtb-backend1.herokuapp.com/rtb_mern/",
      payload:"none"})
      .then((res)=>{  
       
        var pageData = {
          ...res,
   
        }
   
   
          
  for(var key in pageData){
   var keynum = parseInt(key)+1;
  
    pageData[key]["card"] = "card"+keynum;
   
  }
  
   
   
  this.props.setupData(pageData);
   
     }).catch(error => {
       console.log(error);
     }); 
    }
  
  
  });
   
    
  
    
       
  }

  theList(){
     

    return Object.keys(this.props.setup).map((key, i) => {
        console.log(this.props.setup[key]);
        
         return <Child info={this.props.setup[key]} key={i}/>;

    })
 
       }
   
    render(){
 
   
//run the axios get command...
//use the pageContent as a model

//create pageDetailsobject.. 
 

       

      
       
        var tagStyling = {
               padding:10,
               marginTop:100,
               textAlign:"center"
                            }

      return (
        <div>
         <Grid
  container
  spacing={0}
  direction="column"
   justify="center"
   alignItems="center"

  style={{ minHeight: '50vh' }}
>

  <Grid item xs={12}>

   {this.state.view ? <div> 

    <h3 style={tagStyling}>Update</h3>
   
   <table>
       <thead>
           <tr>
               <th>Date</th>
               <th>Story</th>
               <th>Edit</th>
               <th>Delete</th>
           </tr>
       </thead>
       <tbody>
           {this.theList()}
       </tbody>
   </table>

   </div>: null}



   


  
  </Grid>   

</Grid> 
      </div>
      

      );
    } 
  }

    const mapStateToProps = (state) => {
      return {
         setup: state.setup

        
      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return { 
        setupData: (setupObj) => {
          dispatch(setupData(setupObj));
        }
      };
    };
    
    export default connect(mapStateToProps,mapDispatchToProps)(updateCard);
    