import React from "react";

import { connect } from "react-redux";
import { showItem } from "../actions/itemActions";
import { setupData } from "../actions/setupDataActions";
import { catChange } from "../actions/categoryActions";
import Grid from "@material-ui/core/Grid";
import Cookies from "universal-cookie";
import SingleCard from "./card.component";
import axios from "axios";
import Popup from "../components/popup";
import Contentpopup from "../components/contentpopup";

const cookies = new Cookies();

class Grida extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      showContentpopup: false
    };
  }

  componentDidMount() {
    //for inner functions
    var that = this;
    axios
      .post("https://rtb-backend1.herokuapp.com/rtb_mern/guestT", {
        asd: "asda"
      })

      .then(function(response) {
        // The response is a Response instance.
        // You parse the data into a useable format using `.json()`

        cookies.set("token", response.data.h, { path: "/" });

        var tk = String(cookies.get("token"));
        axios
          .get("https://rtb-backend1.herokuapp.com/rtb_mern/cat/usa", {
            headers: { "x-access-token": tk }
          })

          .then(res => {
            var pageData = {
              ...res.data
            };

            for (var key in pageData) {
              var keynum = parseInt(key) + 1;

              pageData[key]["card"] = "card" + keynum;
            }

            that.props.setupData(pageData);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(err => {
        console.error(err);
        alert("Error logging in please try again");
      });
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  toggleContentpopup(sourceContent) {
    console.log(sourceContent);
    this.setState({
      showContentpopup: !this.state.showContentpopup,
      fullStory: sourceContent
    });
  }

  render() {
    //run the axios get command...
    //use the pageContent as a model

    //create pageDetailsobject..
    const catFunction = newCat => {
      this.props.catChange(newCat);
    };

    const action = (arg, cardnum) => {
      // alert('argis'+arg+'cardnumis'+cardnum);
      var formatCard = "card" + cardnum;

      this.props.showItem(formatCard, arg);
    };

    const contentObj = (active, card, category) => {
      if (active === "topic") {
        return this.props.setup[card];
      } else {
        var currentCard = "card" + (parseInt(card) + 1);
        //currrentCard = card1 or card2 or card3 et...
        var realActive = this.props.item.cards[currentCard].active;

        var activeSource = "source" + realActive;

        var current_card = this.props.setup[card];
        return current_card[activeSource];
      }
    };

    var tagStyling = {
      padding: 10,
      marginTop: 100,
      textAlign: "center",
      fontWeight: 300
    };

    var obj = this.props.setup;
    var result = Object.keys(obj).map(function(key) {
      return [Number(key), obj[key]];
    });

    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className="fixed"
          style={{ minHeight: "50vh" }}
        >
          <Grid className="scrolling" item xs={12}>
            <h3 style={tagStyling}>Reporting the Headlines from All Sides</h3>

            {/* <div className="countryNav">

  <a href={'/usa'}><img alt="usa-flag" onClick={()=> catFunction('usa')} src={require("../img/usa-flag-xs.png")} /></a>

  <a href={'/canada'}><img alt="canada-flag" onClick={()=> catFunction('canada')}src={require("../img/canada-flag-xs.png")} /></a>
  
  </div> */}

            {this.props.setup ? (
              <div>
                {result.slice(0, 22).map((card, index) => (
                  <SingleCard
                    topic={contentObj(
                      "topic",
                      index,
                      this.props.category.country
                    )}
                    cardNum={index + 1}
                    showContent={sourceContent => {
                      this.toggleContentpopup(sourceContent);
                    }}
                    action={arg => action(arg, index + 1)}
                    source={contentObj(
                      this.props.item.cards.card1.active,
                      index
                    )}
                  />
                ))}
              </div>
            ) : (
              <p></p>
            )}
            {/*   
  <SingleCard topic={contentObj("topic","0",this.props.category.country)} cardNum = "1" action={(arg) => action(arg,"card1")} source={contentObj(this.props.item.cards.card1.active,"0")}/>
  <SingleCard topic={contentObj("topic","1",this.props.category.country)} cardNum = "2" action={(arg) => action(arg,"card2")} source={contentObj(this.props.item.cards.card2.active,"1")}/>
  <SingleCard topic={contentObj("topic","2",this.props.category.country)} cardNum = "3" action={(arg) => action(arg,"card3")} source={contentObj(this.props.item.cards.card3.active,"2")}/>
  <SingleCard topic={contentObj("topic","3",this.props.category.country)} cardNum = "4" action={(arg) => action(arg,"card4")} source={contentObj(this.props.item.cards.card4.active,"3")}/> */}
            {/* <SingleCard topic={contentObj("topic","4",this.props.category.country)} cardNum = "5" action={(arg) => action(arg,"card5")} source={contentObj(this.props.item.cards.card5.active,"4")}/>
             */}
          </Grid>
        </Grid>
        <button className="howToButton" onClick={this.togglePopup.bind(this)}>
          <span className="brand-color">How</span> do I use this?
        </button>
        {this.state.showPopup ? (
          <Popup
            text='Click "Close Button" to hide popup'
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}

        {this.state.showContentpopup ? (
          <Contentpopup
            text="Active"
            content={this.state.sourceContent ? this.state.sourceContent : null}
            closePopup={this.toggleContentpopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    item: state.item,
    setup: state.setup,
    category: state.category.chosenCategory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showItem: (cardNumber, activeSource) => {
      dispatch(showItem(cardNumber, activeSource));
    },
    setupData: setupObj => {
      dispatch(setupData(setupObj));
    },
    catChange: catUpdate => {
      dispatch(catChange(catUpdate));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Grida);
