import React from "react";

// import { BrowserRouter as Router, Route, Link} from "react-router-dom";
import "../../App.css";

import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { showItem } from "../actions/itemActions";
import { toggle } from "../actions/toggle";
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from "@material-ui/core/CardActions";

// import CardMedia from '@material-ui/core/CardMedia';
import Button from "@material-ui/core/Button";
import { Swipeable } from "react-swipeable";
import Contentpopup from "../components/contentpopup";

class singleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentInfo: "",
      showContentpopup: false
    };
  }

  toggleContentpopup() {
    this.setState({
      contentInfo: this.props.source,
      showContentpopup: !this.state.showContentpopup
    });
  }

  render() {
    function characterLimit(subheader) {
      if (subheader.length > 62) {
        var newSub = subheader.substring(0, 100) + "...";
        return newSub;
      } else {
        return subheader;
      }
    }

    const activeCheck = num => {
      if (num === this.props.source.number) {
        return "contained";
      } else {
        return "text";
      }
    };

    const formatSourceNames = src => {
      switch (src) {
        case "the-hill":
          return "Hill";
        case "the-washington-post":
          return "W.Post";
        case "nbc-news":
          return "NBC";
        case "fox-news":
          return "Fox";
        case "bbc-news":
          return "bbc";
        default:
          return src;
      }
    };

    const swipeToggle = dir => {
      var actionNumber = this.props.source.number;

      if (dir === "Left") {
        if (actionNumber === 4) {
          actionNumber = 1;
        } else {
          actionNumber += 1;
        }
        this.props.action(actionNumber);

        document.ontouchmove = function(e) {
          e.preventDefault();
        };
      } else if (dir === "Right") {
        if (actionNumber === 1) {
          this.props.action(4);
        } else {
          this.props.action(actionNumber - 1);
        }

        document.ontouchmove = function(e) {
          e.preventDefault();
        };
      }

      document.ontouchmove = function(e) {
        return true;
      };

      this.props.toggle("off");
    };

    function toggleView(that) {
      var currentToggle = that.props.toggledItem.toggle;

      if (currentToggle === "off") {
        that.props.toggle("on");
      } else {
        that.props.toggle("off");
      }
    }

    var imgStyle = {
      backgroundImage:
        "url('" +
        (this.props.source.image
          ? this.props.source.image
          : this.props.topic.image) +
        "')"
    };
    return (
      <Swipeable
        onSwiped={eventData => swipeToggle(eventData.dir)}
        trackTouch={true}
        trackMouse={true}
      >
        <Card className="anotheronebitesthedust">
          <a
            className="subHead--text"
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => this.toggleContentpopup()}
          >
            <CardHeader
              className="card--cardHeader"
              title={this.props.source.headline}
            ></CardHeader>
          </a>

          <CardActions>
            <Button
              onClick={() => this.props.action(1)}
              variant={activeCheck(1)}
              size="medium"
              color="primary"
            >
              {formatSourceNames(this.props.topic.source1.src)}
            </Button>
            <Button
              onClick={() => this.props.action(2)}
              variant={activeCheck(2)}
              size="large"
              color="primary"
            >
              {formatSourceNames(this.props.topic.source2.src)}
            </Button>
            <Button
              onClick={() => this.props.action(3)}
              variant={activeCheck(3)}
              size="large"
              color="primary"
            >
              {formatSourceNames(this.props.topic.source3.src)}
            </Button>

            {this.props.topic.source4.headline === "headline" ? null : (
              <Button
                onClick={() => this.props.action(4)}
                variant={activeCheck(4)}
                size="large"
                color="primary"
              >
                {formatSourceNames(this.props.topic.source4.src)}
              </Button>
            )}
          </CardActions>
          <div className="subHead"></div>
          <div onClick={() => toggleView(this)}>
            <div className="sweetDreams" style={imgStyle}></div>
            <div className="description-text-container">
              <h2 className="storyDate">{" " + this.props.topic.story}</h2>
              <p>{this.props.source.blurb}</p>
              <p
                className="read-more"
                onClick={() => this.toggleContentpopup()}
              >
                Read more
              </p>
            </div>
          </div>
        </Card>
        {this.state.showContentpopup ? (
          <Contentpopup
            text="Active"
            content={this.state.contentInfo ? this.state.contentInfo : null}
            closePopup={this.toggleContentpopup.bind(this)}
          />
        ) : null}
      </Swipeable>
    );
  }
}

const mapStateToProps = state => {
  return {
    item: state.item,
    toggledItem: state.toggle
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showItem: (cardNumber, activeSource) => {
      dispatch(showItem(cardNumber, activeSource));
    },
    toggle: pay => {
      dispatch(toggle(pay));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(singleCard);
