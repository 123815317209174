import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/containers/App';
import * as serviceWorker from './app/serviceWorker';
import store from "./app/store";
 
import { Provider }from "react-redux";
 
  store.dispatch({
    type:"ADD",
    payload:102
  });
  
  store.dispatch({
    type:"SET_NAME",
    payload:"Joey"
  });
  
  

serviceWorker.unregister();

ReactDOM.render(
<Provider store={store}>
<App />
</Provider>, document.getElementById('root'));
