const agenDataReducer = (state={

  general: {
      manualInput: "Manual Input",
      confirmTopic: "Confirmed Topic Title",
      confirmImg: "imgURL",
      category:"category",
      showConfirm:0,
      confirmSources: [
        {
        headline:"headline",
        author:"author",
        link:"link",
        blurb:"blurb",
        src:"source",
        date:"date"
        },
        {
        headline:"headline",
        author:"author",
        link:"link",
        blurb:"blurb",
        src:"source",
        date:"date"
        },
        {
          headline:"headline",
          author:"author",
          link:"link",
          blurb:"blurb",
          src:"source",
          date:"date"
          },
        {
        headline:"headline",
        author:"author",
        link:"link",
        blurb:"blurb",
        src:"source",
        date:"date"
        },
        

      ]
  }
  
  
  },action) => {
    var payload = action.payload;
    var general = state.general;
       if(action.type === 'GEN_DATA'){
      
         state = {
           ...state,
          general:{
            ...general,
            manualInput: payload
          }
         }
       
       }
       else if (action.type === 'CONFIRM_TOPIC'){
         

        state = {
          ...state,
         general:{
           ...general,
           confirmTopic: payload
         }
        }
      
        }

        else if (action.type === 'CONFIRM_IMG'){
         
  
          state = {
            ...state,
           general:{
             ...general,
             confirmImg: payload
           }
          }
        
          }

        else if (action.type === 'CONFIRM_SOURCES'){
          var theData = action.payload;
          var theSource = action.theSource;
           var confirmSources = state.general.confirmSources;

          state = {
            ...state,
           general:{
             ...general,
             confirmSources:[
               ...confirmSources,
               
              ]
           }
          }

          state.general.confirmSources[theSource] = theData; 
 
        
          }

        else if (action.type === 'SHOW_CONFIRM'){
           
          state = {
            ...state,
           general:{
             ...general,
             showConfirm: payload
           }
          }
        
          }
         
       
       return state;
     
     };
   
     export default agenDataReducer;
     