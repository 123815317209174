import React from "react";
import "../../App.css";
import tutorialLogo from "../img/tutorial.png";

class Contentpopup extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    console.log(this.props);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.closePopup();
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup\_inner">
          <div
            ref={this.wrapperRef}
            onClick={e => console.log(e)}
            className="story-wrap"
          >
            <button
              className="close-top-right"
              onClick={this.props.closePopup}
            ></button>
            <h2>{this.props.content ? this.props.content.headline : null}</h2>

            <p>{this.props.content ? this.props.content.blurb : "none"}</p>

            <p>
              Read the original source:{" "}
              <a target="_blank" href={this.props.content.link}>
                {this.props.content.link}
              </a>
            </p>
            {/* <button className="close-tutorial" onClick={this.props.closePopup}>
              Close
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Contentpopup;
