export function showItem(cardNumber,activeSource){
    return{
        type:"SHOW_ITEM",
        payload:cardNumber,
        src:activeSource
    };
}
export function setAge(age){
    return{
        type:"SET_AGE",
        payload:age
    };
}