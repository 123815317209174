import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Cookies from "universal-cookie";
import { ReactTitle } from "react-meta-tags";

const cookies = new Cookies();

export default class IsItDone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submission_made: false,
      email: "",
      phone: ""
    };
  }

  componentDidMount() {
    //for inner functions
    var that = this;
    axios
      .post("https://rtb-backend1.herokuapp.com/rtb_mern/guestT", {
        asd: "asda"
      })

      .then(function(response) {
        // The response is a Response instance.
        // You parse the data into a useable format using `.json()`

        cookies.set("token", response.data.h, { path: "/" });

        var tk = String(cookies.get("token"));
        that.setState({
          token: tk
        });
      })
      .catch(err => {
        console.error(err);
        alert("Error logging in please try again");
      });
  }

  render() {
    const submission = () => {
      let newSubmission = {
        email: this.state.email,
        phone: this.state.phone
      };

      let tk = this.state.token;
      axios
        .post(
          "https://rtb-backend1.herokuapp.com/rtb_mern/isitoversubmit",
          newSubmission,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": tk
            }
          }
        )
        .then(res => {
          console.log(res.data);

          if (res.data.submission === "submission_success") {
            this.setState({ submission_made: true });
          }
        });
    };

    const handleUpdate = event => {
      const fieldName = event.target.name;
      const fieldValue = event.target.value;

      this.setState({
        [fieldName]: fieldValue
      });
    };
    return (
      <div>
        <ReactTitle title="Is the Election Over Yet?" />

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100%", paddingTop: 100 }}
        >
          <Grid item xs={12}>
            <div style={{ padding: 10, lineHeight: 1.5 }}>
              <h3 className="heading-text">
                Get an email or text when this election is finally over{" "}
              </h3>
              <div
                className={
                  this.state.submission_made
                    ? "banner-container hidden"
                    : "banner-container"
                }
              >
                {" "}
                <img
                  className={
                    this.state.submission_made
                      ? "banner-gif hidden"
                      : "banner-gif"
                  }
                  alt="banner"
                  src={require("../img/waiting.gif")}
                />
              </div>

              <p>
                We'll text or email you the name of the President once the dust
                settles so you can avoid the incredibly biased and exhaustive
                news cycles.
              </p>
              <hr />
              <div
                className={
                  this.state.submission_made
                    ? "submission-container hidden"
                    : "submission-container"
                }
              >
                <div className="get-email">
                  <h4>Get an Email</h4>
                  <span className="text-sample">
                    Something like, <br></br>
                    <span>'Kanye West is the new President'</span>
                  </span>
                  <p>
                    <input
                      className="input email"
                      type="text"
                      name="email"
                      placeholder="joe.biden@hotmail.com"
                      onChange={e => handleUpdate(e)}
                      value={this.state.email}
                    />
                  </p>
                </div>

                <div className="get-text">
                  <h4>Get a Text</h4>
                  <span className="text-sample">
                    or <br></br>
                    <span>'2020 is officially cancelled'</span>
                  </span>
                  <p>
                    <input
                      className="input text"
                      type="text"
                      name="phone"
                      placeholder="(366) 253-87867"
                      value={this.state.phone}
                      onChange={e => handleUpdate(e)}
                    />
                  </p>
                </div>

                <div className="submit-container">
                  <button
                    className="submit-notification"
                    onClick={() => submission()}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div
                className={
                  this.state.submission_made
                    ? "submission-container-made"
                    : "submission-container-made hidden"
                }
              >
                <h3 className="sign-up-confirmation">
                  Thanks for signing up. We'll notify you as soon as this whole
                  thing is over{" "}
                  <span role="img" aria-label="peace-sign">
                    ✌️
                  </span>
                </h3>

                <p className="sign-up-centered">
                  <img
                    className="facepalm"
                    alt="facepalm emoji"
                    src={require("../img/facepalm.gif")}
                  />
                </p>

                <div
                  className={
                    this.state.submission_made
                      ? "submit-container hidden"
                      : "submit-container "
                  }
                >
                  <button
                    className="submit-notification"
                    onClick={() => console.log(this.state)}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <hr />
              <p className="fine-details">
                Your phone number or email won't be used for anything other than
                this 1-time notification. All information will be auto-deleted
                once the notification is sent. ReadTheBias is not affiliated
                with any news organization.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
