const setupReducer= (state={
    0:{
        story:"Topic1",
        image:"Image1",
        date:"Date1",
        category:"category",
        source1:{
          number:1,
          src:"Source1",
          headline:"Headline1",
          link:"Subheadline1",
          author:"Author",
          date:"date",
          blurb:"blurb"
        },
        source2:{
          number:2,
          src:"FOx1_2",
          headline:"Headline12",
          link:"Subheadline12",
          author:"Author2",
          date:"date2",
          blurb:"blurb2"
        },
        source3:{
          number:3,
          src:"FOx1_3",
          headline:"Headline13",
          link:"Subheadline13",
          author:"Author3",
          date:"date3",
          blurb:"blurb3"
        },
        source4:{
          number:4,
          src:"FOx4",
          headline:"Headline14",
          link:"Subheadline14",
          author:"Autho4r",
          date:"date4",
          blurb:"blurb4"
        }
      },
      1:{
          topic:"Topic2",
          image:"Image2",
          date:"date2",
          category:"category",
          source1:{
            number:1,
            src:"FOx2_1",
            headline:"Headline1",
            link:"Subheadline1",
            author:"Author",
            date:"date",
            blurb:"blurb"
          },
          source2:{
            number:2,
            src:"FOx2_2",
            headline:"Headline12",
            link:"Subheadline12",
            author:"Author2",
            date:"date2",
            blurb:"blurb2"
          },
          source3:{
            number:3,
            src:"FOx3",
            headline:"Headline13",
            link:"Subheadline13",
            author:"Author3",
            date:"date3",
            blurb:"blurb3"
          },
          source4:{
            number:4,
            src:"FOx4",
            headline:"Headline14",
            link:"Subheadline14",
            author:"Autho4r",
            date:"date4",
            blurb:"blurb4"
          } 

        },
        2:{
          
           topic:"Topic3",
          image:"Image1",
          date:"date",
          catgeory:"category",
          source1:{
            number:1,
            src:"FOx",
            headline:"Headline1",
            link:"Subheadline1",
            author:"Author",
            date:"date",
            blurb:"blurb"
          },
          source2:{
            number:2,
            src:"FOx",
            headline:"Headline12",
            link:"Subheadline12",
            author:"Author2",
            date:"date2",
            blurb:"blurb2"
          },
          source3:{
            number:3,
            src:"FOx3",
            headline:"Headline13",
            link:"Subheadline13",
            author:"Author3",
            date:"date3",
            blurb:"blurb3"
          },
          source4:{
            number:4,
            src:"FOx4",
            headline:"Headline14",
            link:"Subheadline14",
            author:"Autho4r",
            date:"date4",
            blurb:"blurb4"
          }
        },
        3:{
          topic:"Topic4",
          image:"Image1",
          date:"date",
          category:'category',
          source1:{
            number:1,
            src:"FOx",
            headline:"Headline1",
            link:"Subheadline1",
            author:"Author",
            date:"date",
            blurb:"blurb"
          },
          source2:{
            number:2,
            src:"FOx",
            headline:"Headline12",
            link:"Subheadline12",
            author:"Author2",
            date:"date2",
            blurb:"blurb2"
          },
          source3:{
            number:3,
            src:"FOx3",
            headline:"Headline13",
            link:"Subheadline13",
            author:"Author3",
            date:"date3",
            blurb:"blurb3"
          },
          source4:{
            number:4,
            src:"FOx4",
            headline:"Headline14",
            link:"Subheadline14",
            author:"Autho4r",
            date:"date4",
            blurb:"blurb4"
          }
        },
        4:{
          topic:"Topic5",
          image:"Image1",
          date:"date",
          category:'category',
          source1:{
            number:1,
            src:"FOx",
            headline:"Headline1",
            link:"Subheadline1",
            author:"Author",
            date:"date",
            blurb:"blurb"
          },
          source2:{
            number:2,
            src:"FOx",
            headline:"Headline12",
            link:"Subheadline12",
            author:"Author2",
            date:"date2",
            blurb:"blurb2"
          },
          source3:{
            number:3,
            src:"FOx3",
            headline:"Headline13",
            link:"Subheadline13",
            author:"Author3",
            date:"date3",
            blurb:"blurb3"
          },
          source4:{
            number:4,
            src:"FOx4",
            headline:"Headline14",
            link:"Subheadline14",
            author:"Autho4r",
            date:"date4",
            blurb:"blurb4"
          }
        }
  },action) => {
    if(action.type === 'SETUP_DATA'){
       state = action.payload;
        
     
     
    }
    else {
     }
    
    return state;
  
  };

  export default setupReducer;
  