import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

export default class About extends Component {
  render() {
    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100%", paddingTop: 100 }}
        >
          <Grid item xs={12}>
            <div style={{ padding: 10, lineHeight: 1.5 }}>
              <h3>What is this?</h3>
              <p>
                ReadTheBias.com is a website that showcases how major news
                publications report the same issues differently. <br></br>The
                language, media and tone used by a news publication can
                significantly influence a reader's perception of a particular
                issue. This website doesn't aim to tell you how you should think
                about an issue but rather tries to show a diverse view through
                headlines all over the political spectrum.
              </p>

              <h3>Why should I care?</h3>
              <p>
                News organizations, their sponsors and interests massively
                attempt to influence your perspective everyday. ReadTheBias is
                taking some of that power back by presenting a holistic view of
                news stories.
              </p>

              <h3>How does this site make money?</h3>
              <p>
                {" "}
                It doesn't. ReadTheBias won't ever have ads, doesn't collect
                user info and does not compete with news services. This is only
                made to assist you to make <strong>your own decision</strong> on
                news stories.
              </p>

              <h3>Who Made This?</h3>
              <p>
                My name's Zalmy Karimi and I'm a tech developer based out of
                Toronto, Canada. I'm not affiliated with any news organization.
                In order to be completely transparent about my political bias, I
                would say my political views tend to be Centre-Left.
                Understanding and working with this bias has been tremendously
                important in developing ReadTheBias.com. My goals aren't aligned
                with an ideology and I genuinely hope to positively impact
                public discourse for all political viewpoints. Connect with me
                on twitter @zedkay22.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
