import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as dbCall from "../../actions/dbApi";
import { connect } from "react-redux";
import { fetchData } from "../../actions/fetchDataActions";
import { formatSources } from "../../dataFiles/content";
import {
  agenData,
  confirmTopic,
  confirmImg,
  confirmCat,
  confirmSources,
  showConfirm
} from "../../actions/agenAction";
import axios from "axios";

class suggestionTab extends Component {
  state = {
    view: false,
    tk: false,
    firstpull: []
  };

  componentDidMount() {
    function getCookie(name) {
      function escape(s) {
        return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, "\\$1");
      }
      var match = document.cookie.match(
        RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
      );
      return match ? match[1] : null;
    }

    var tk = getCookie("token");
    this.setState({ tk: tk });

    var searchUrl =
      "https://rtb-backend1.herokuapp.com/rtb_mern/adminTokenCheck";
    fetch(searchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": tk
      }
    }).then(response => {
      if (
        response.status == 400 ||
        response.status == 401 ||
        response.status == 500
      ) {
        window.location.href = "/login";
      } else {
        this.setState({ view: true });

        this.autoSuggest();

        return response.json();
      }
    });
  }

  autoSuggest = e => {
    //make an api call for that value
    dbCall
      .dataFetch({
        method: "get",
        tk: this.state.tk,
        url: "https://rtb-backend1.herokuapp.com/rtb_mern/comparison",
        payload: "none"
      })
      .then(res => {
        this.setState({ firstpull: res });
        console.log(this.state);
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const changeHandle = e => {
      this.props.agenData(e.target.value);
    };

    const topicHandle = e => {
      this.props.confirmTopic(e.target.value);
    };

    const imgHandle = e => {
      this.props.confirmImg(e.target.value);
    };

    const catHandle = e => {
      this.props.confirmCat(e.target.value);
    };

    const toggleView = e => {
      this.props.firstData.general.showConfirm === 0
        ? this.props.showConfirm(1)
        : this.props.showConfirm(0);
    };

    const changeSource = (e, confirmSource) => {
      var iteration = e.target.attributes.getNamedItem("data-store").value;
      var fetch = this.props.data.payload[iteration];
      this.props.confirmSources(fetch, confirmSource);
    };

    const updateSource = (topic, story, confirmSource) => {
      var newObj = {
        headline: this.state.firstpull[topic][story].title,
        author: this.state.firstpull[topic][story].author,
        link: this.state.firstpull[topic][story].url,
        blurb: this.state.firstpull[topic][story].description,
        src: this.state.firstpull[topic][story].source,
        date: this.state.firstpull[topic][story].publishedAt,
        image: this.state.firstpull[topic][story].urlToImage
      };

      this.props.confirmSources(newObj, confirmSource);
    };

    const updateImg = imgUrl => {
      this.props.confirmImg(imgUrl);
    };

    var reset = {
      headline: "headline",
      author: "author",
      link: "link",
      blurb: "blurb",
      src: "source",
      date: "date"
    };

    const removeSource = e => {
      var sourceDelete = e.target.attributes.getNamedItem("data-delete").value;

      this.props.confirmSources(reset, sourceDelete);
    };

    const Publish = e => {
      e.preventDefault();

      var currentdate = new Date().toISOString().slice(0, 10);

      var storyTitle = this.props.firstData.general.confirmTopic;
      //for the sake of launching for now just include an image URL instead of upload...
      var storyImg = this.props.firstData.general.confirmImg;

      var storyCat = "usa";

      //manipulate this.props.general.confirmSources to look like the required axios POST data
      var formattedSources = [];

      for (var xa = 0; xa <= 3; xa++) {
        var convert = this.props.firstData.general.confirmSources[xa];

        for (var yz = 0; yz < formatSources.length; yz++) {
          if (convert.src === formatSources[yz][0]) {
            formattedSources[xa] = formatSources[yz][1];
            break;
          }
        }
      }

      var newStory = {
        story: storyTitle,
        image: storyImg,
        date: currentdate,
        category: storyCat,
        source1: {
          number: "1",
          ...this.props.firstData.general.confirmSources[0],
          src: formattedSources[0]
        },
        source2: {
          number: "2",
          ...this.props.firstData.general.confirmSources[1],
          src: formattedSources[1]
        },
        source3: {
          number: "3",
          ...this.props.firstData.general.confirmSources[2],
          src: formattedSources[2]
        },
        source4: {
          number: "4",
          ...this.props.firstData.general.confirmSources[3],
          src: formattedSources[3]
        }
      };

      console.log(newStory);

      axios
        .post("https://rtb-backend1.herokuapp.com/rtb_mern/add", newStory)
        .then(res => console.log(res.data));
    };

    var dataArrayStyle = {
      border: "1px solid black",
      borderRadius: "5px",
      padding: "10px",
      height: "150px",
      width: "100%"
    };

    var innerLeftStyle = {
      width: "60%",
      float: "left",
      display: "inline-block"
    };

    var innerRightStyle = {
      width: "40%",
      float: "right",
      display: "inline-block"
    };

    var dataload = this.props.data.payload;
    var dataArray = [];
    for (var ix = 0; ix < dataload.length; ix++) {
      dataArray.push(
        <div style={dataArrayStyle} key={ix}>
          <div style={innerLeftStyle}>
            <h3>{dataload[ix].src + ix}</h3>
            <a href={dataload[ix].link}>
              <p>{dataload[ix].headline}</p>
            </a>
          </div>
          <div style={innerRightStyle}>
            Set As:
            <button
              data-store={ix}
              onClick={e => {
                changeSource(e, 0);
              }}
            >
              1
            </button>
            <button
              data-store={ix}
              onClick={e => {
                changeSource(e, 1);
              }}
            >
              2
            </button>
            <button
              data-store={ix}
              onClick={e => {
                changeSource(e, 2);
              }}
            >
              3
            </button>
            <button
              data-store={ix}
              onClick={e => {
                changeSource(e, 3);
              }}
            >
              4
            </button>
          </div>
        </div>
      );
    }

    var headArray = [];

    this.state.firstpull.forEach(function(item, index) {
      item.forEach(function(article, num) {
        headArray.push(
          <div className="singleHead">
            <div className="left">
              {article.title} <br />
              {article.source || article.source_name} <br />
              {article.description}
              <br />
              {article.publishedAt}
              <br />
              <a href={article.url} target="_blank">
                URL
              </a>
              <img
                onClick={() => updateImg(article.urlToImage)}
                alt="suggestedimg"
                src={article.urlToImage}
                className="thumb"
              />
            </div>

            <div className="rightStyle">
              Set As:
              <button
                onClick={() => {
                  updateSource(index, num, 0);
                }}
              >
                1
              </button>
              <button
                onClick={() => {
                  updateSource(index, num, 1);
                }}
              >
                2
              </button>
              <button
                onClick={() => {
                  updateSource(index, num, 2);
                }}
              >
                3
              </button>
              <button
                onClick={() => {
                  updateSource(index, num, 3);
                }}
              >
                4
              </button>
            </div>
          </div>
        );
      });
    });

    for (var ix = 0; ix < this.state.firstpull.length; ix++) {
      headArray.push(
        <div className="singleHead" key={ix}>
          {this.state.firstpull[ix].src} - {this.state.firstpull[ix].headline}
        </div>
      );
    }

    var manualClick = e => {
      //get the value of the current input field
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;

      var d2 = dd - 2;
      var from = yyyy + "-" + mm + "-" + d2;

      var dateFrom = from;
      var dateTo = today;
      var query = this.props.firstData.general.manualInput;

      //make an api call for that value
      dbCall
        .dataFetch({
          method: "get",
          tk: this.state.tk,
          url:
            "https://rtb-backend1.herokuapp.com/rtb_mern/sourcegrab/" +
            query +
            "/" +
            dateFrom +
            "/" +
            dateTo,
          payload: "none"
        })
        .then(res => {
          this.props.fetchData(res);
        })
        .catch(error => {
          console.log(error);
        });
    };

    var confirmingLoad = this.props.firstData.general.confirmSources;
    var confirmArray = [];
    for (var i = 0; i < confirmingLoad.length; i++) {
      var a = i + 1;
      confirmArray.push(
        <div style={dataArrayStyle} key={i}>
          <div style={innerLeftStyle}>
            <h3>{confirmingLoad[i].src}</h3>
            <a key={i} href={confirmingLoad[i].link}>
              <p key={i}>{confirmingLoad[i].headline}</p>
            </a>
          </div>
          <div style={innerRightStyle}>
            <button
              data-delete={i}
              onClick={e => {
                removeSource(e);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      );
    }

    var manualButton = {
      display: "inline-block"
    };

    return (
      <div>
        <Grid container spacing={0} direction="column">
          <Grid
            style={
              this.props.firstData.general.showConfirm === 1
                ? { display: "none" }
                : {}
            }
            item
            xs={12}
          >
            {this.state.view ? (
              <div className="autosuggest_box">
                <h3 className="autosuggest_title">AutoSuggest</h3>
              </div>
            ) : null}

            <div className="allHeads">{headArray}</div>

            <div>
              <input
                type="text"
                name="manualSearch"
                onChange={changeHandle}
                value={this.props.firstData.general.manualInput}
                placeholder="Manual Search A Topic"
                className="autosuggest_manual-input"
              ></input>{" "}
              <div className="autosuggest_button-container">
                <Button
                  onClick={manualClick}
                  style={{ float: "right" }}
                  variant="contained"
                >
                  Go
                </Button>
              </div>
            </div>
            <div>{dataArray}</div>
          </Grid>

          <Grid
            style={
              this.props.firstData.general.showConfirm === 0
                ? { display: "none" }
                : {}
            }
            item
            xs={12}
          >
            <h3 className="autosuggest_title">Confirmation Panel</h3>

            <input
              type="text"
              name="topicTitle"
              onChange={topicHandle}
              value={this.props.firstData.general.confirmTopic}
              placeholder="Manual Search"
              className="autosuggest_manual-input"
            ></input>
            <hr></hr>

            {/* img logic */}
            <input
              type="text"
              name="topicImg"
              onChange={imgHandle}
              value={this.props.firstData.general.confirmImg}
              placeholder="Img Handle"
              className="autosuggest_manual-input"
            ></input>

            <input
              type="text"
              name="topicCat"
              onChange={catHandle}
              value="usa"
              placeholder="cat Handle"
              className="autosuggest_manual-input"
            ></input>

            <div>{confirmArray}</div>
          </Grid>

          <Button
            onClick={toggleView}
            variant="contained"
            style={{ position: "fixed", bottom: 0, left: 0 }}
          >
            {this.props.firstData.general.showConfirm === 0
              ? "Confirm Panel"
              : "Results Panel"}
          </Button>

          <Button
            onClick={Publish}
            variant="contained"
            style={
              this.props.firstData.general.showConfirm === 0
                ? { display: "none" }
                : { position: "fixed", bottom: 0, right: 0 }
            }
          >
            PUBLISH STORY
          </Button>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.fetchData,
    firstData: state.agenData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: data => {
      dispatch(fetchData(data));
    },
    agenData: data => {
      dispatch(agenData(data));
    },
    confirmTopic: data => {
      dispatch(confirmTopic(data));
    },
    confirmImg: data => {
      dispatch(confirmImg(data));
    },
    confirmCat: data => {
      dispatch(confirmCat(data));
    },
    confirmSources: (data, source) => {
      dispatch(confirmSources(data, source));
    },
    showConfirm: data => {
      dispatch(showConfirm(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(suggestionTab);
