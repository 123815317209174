import React from 'react';
 import TextField from '@material-ui/core/TextField';
 import Button from '@material-ui/core/Button';


 
const ContentFromTab = (props) => {

   

    function fieldChange(event){
     
        const fieldName = event.target.name;
       const fieldValue = event.target.value;
       props.handleaction(fieldName, fieldValue);
    
}

     function fillUp(){



      var searchUrl = 'https://articlegoose.herokuapp.com/use.php?auth=zk&url='+props.content.autofill;
      fetch(searchUrl,{
          method: 'GET',  
        })
      .then(response =>  {
         
       var newData=response.json();
      
       newData.then(function(data){
          console.log(data);
 
          props.handleaction('headline',data.title);
          //link
          props.handleaction('link',data.url);
          //author
          props.handleaction('author','TBD');
  
          //date
          props.handleaction('date',data.date.date);
  
          //blurb
          props.handleaction('blurb',data.articleText);
   
          console.log('done');


       });
    }); 


        //do the ajax call 

        //document get element by id and fill up 

        //headline
        

     }
 
    return(
        <div>
            
            <TextField 
          name="autofill"
        onChange={(event) => props.handleaction('autofill', event.target.value)}
          label="Enter URL for AutoFill"
            margin="normal"
            className="autofillLink createNewText"
            value={props.content.autofill}


        />
          <Button onClick={(event) => fillUp()} type="button" variant="contained" >AUTOFILL</Button>

        <hr></hr>

           
        <TextField
          id={"headline_"+props.for}
          name="headline"
        onChange={(event) => fieldChange(event)}
          label="Headline"
            margin="normal"
            value={props.content.headline}
            className="createNewText"
        />
        <br></br>
          <TextField
          id={"link_"+props.for}
          name="link"
          onChange={(event) => fieldChange(event)}

          label="Link"
            margin="normal"
            value={props.content.link}
            className="createNewText"

        />
        <br></br>
          <TextField
          id={"src_"+props.for}
          name="src"
          onChange={(event) => fieldChange(event)}

          label="Src"
            margin="normal"
            value={props.content.src}
            className="createNewText"

        />
                <br></br>

         <TextField
          id={"date_"+props.for}
          name="date"
          onChange={(event) => fieldChange(event)}

          label="Date"
            margin="normal"
            value={props.content.date}
            className="createNewText"

        />
                <br></br>

         <TextField
          id={"blurb_"+props.for}
          name="blurb"
          onChange={(event) => fieldChange(event)}

          label="Blurb"
            margin="normal"
            value={props.content.blurb}
            className="createNewText"

        />



        </div>
    )
}

export  default ContentFromTab;